<template>
  <Stack align="center" gap="xs">
    <input
      type="checkbox"
      class="ui-hidden"
      :id="id"
      :value="value"
      :name="name"
      :disabled="disabled"
      :checked="value || checked"
    />
    <Stack
      align="center"
      justify="center"
      :class="[
        'ui-min-h-[20px] ui-min-w-[20px] ui-cursor-pointer ui-border-md ui-rounded-sm',
        'hover:ui-ring-[6px] hover:ui-ring-blue-lighter',
        'active:ui-ring-[6px] active:ui-ring-blue-light',
        checked
          ? 'ui-border-mkm-blue-default ui-bg-mkm-blue-default'
          : 'ui-border-charcoal-light',
        disabled &&
          '!ui-cursor-default !ui-border-grey-lighter !ui-bg-grey-lighter hover:!ui-ring-0 active:!ui-ring-0',
        labelHovered && !disabled && 'ui-ring-[6px] ui-ring-blue-lighter',
        labelActive && !disabled && 'ui-ring-[6px] !ui-ring-blue-light',
      ]"
      @click="toggleCheckbox"
    >
      <Icon
        v-if="checked && !indeterminate"
        name="check-thick"
        :size="14"
        :color="getColor(disabled ? 'grey-default' : 'white')"
      />
      <Icon
        v-else-if="checked && indeterminate"
        name="minus-thick"
        :size="14"
        :color="getColor(disabled ? 'grey-default' : 'white')"
      />
    </Stack>
    <label
      :for="id"
      :class="[
        'ui-text-base ui-font-semi ui-cursor-pointer',
        labelVisible === false && 'ui-sr-only',
        disabled && 'ui-text-grey-default',
      ]"
      @mouseover="labelHovered = true"
      @mouseleave="labelHovered = false"
      @mousedown="labelActive = true"
      @mouseup="labelActive = false"
      @click="toggleCheckbox"
    >
      {{ label }}
    </label>
  </Stack>
</template>

<script lang="ts" setup>
import Icon from "../../Icon/Icon.vue";
import Stack from "../../Layout/Stack/Stack.vue";
import type { CheckboxProps } from "./types";
import { getColor } from "mkm-avengers";
import { ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<CheckboxProps>(), {
  modelValue: false,
  labelVisible: true,
  indeterminate: false,
  disabled: false,
});

const {
  id,
  modelValue,
  value,
  name,
  label,
  labelVisible,
  indeterminate,
  disabled,
} = toRefs(props);

const emit = defineEmits<{
  (event: "update:modelValue", checked: boolean): void;
}>();

const checked = ref<boolean>(props.modelValue);
const labelHovered = ref<boolean>(false);
const labelActive = ref<boolean>(false);

const toggleCheckbox = () => {
  if (!props.disabled) {
    const _newValue = !checked.value;

    checked.value = _newValue;
    emit("update:modelValue", _newValue);
  }
};

watch(modelValue, () => {
  checked.value = modelValue.value;
});
</script>
